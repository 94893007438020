//API URL
export const VIDEO_TOKBOX_URI = 'https://tokbox.com/embed/embed/ot-embed.js'
export const VIDEO_TOKBOX_CODE = 'cb43b778-2e49-40e7-99d0-c9f29dfb6791'

//API URL
//API URL
// export const API_URL = process.env.REACT_APP_API_BASE_URL === 'PRODUCTION' ? 
// 'https://asp.clinicabaviera.com/APIPacientev2/api'
// : 'https://aspdev.clinicabaviera.com/APIPacientev2/api'
export const API_URL = 'https://asp.clinicabaviera.com/APIPacientev2/api';
//export const API_URL = 'https://aspdev.clinicabaviera.com/APIPacientev2/api';
export const WEB_URL =
  'https://areapaciente.clinicabaviera.com/Onboarding/Videollamada?room='

export const API_USER = 'RLo@d'
export const API_PASSWORD = 'Bavi77'

// export const API_USER = "OPTRETINA";
// export const API_PASSWORD = "M@rcoPolo2019";
export const NEW_ACCOUNT_URL =
  'https://www.clinicabaviera.com/area-paciente/preacceso.php'

//API End Points
export const AUTHENTICATE = `${API_URL}/authenticate/authenticate`
export const LOGIN = `${API_URL}/authenticate/authenticate`
export const SESSION = `${API_URL}/authenticate/LoginBio`

export const GET_USER_DATA = `${API_URL}/Paciente/Get`
export const SET_USER_DATA = `${API_URL}/Paciente/Set`
export const RESET_PASSWORD = `${API_URL}/Paciente/ResetPassword`
export const FORGOT_PASSWORD = `${API_URL}/Paciente/ContrasenyaOlvidada`
export const DELETE_USER = `${API_URL}/Paciente/SetBajaAreaPaciente`
export const RESET_IS_RESET_PASSWORD = `${API_URL}/Paciente/SetIsPasswordReset`

export const GET_CONTACT_REASONS = `${API_URL}/Contacto/GetMotivos`
export const REQUEST_CONTACT = `${API_URL}/Contacto/Solicitud`

export const GET_MEDICATIONS = `${API_URL}/Medicacion/Get`
export const GET_MEDICATION_PDF = `${API_URL}/Medicacion/GetPdf`

export const GET_BUDGETS = `${API_URL}/Presupuesto/Get`
export const GET_EXPIRE_BUDGETS = `${API_URL}/Presupuesto/GetProximosEnCaducar`
export const GET_BUDGET_PDF = `${API_URL}/Presupuesto/GetPdf`

export const GET_INVOICES = `${API_URL}/Factura/Get`
export const GET_INVOICE_PDF = `${API_URL}/Factura/GetPdf`
export const GET_FINANCES = `${API_URL}/Financiacion/Get`
export const GET_FINANCE_PDF = `${API_URL}/Financiacion/GetPdf`

export const GET_NOTES = `${API_URL}/Justificante/Get`

export const GET_REPORTS = `${API_URL}/InformeMedico/Get`
export const GET_REPORT_PDF = `${API_URL}/InformeMedico/GetPdf`
export const GET_REPORT_TYPES = `${API_URL}/InformeTipo/Get`

export const GET_SCORES = `${API_URL}/GradoSatisfaccion/GetTipos`
export const REQUEST_SCORE = `${API_URL}/GradoSatisfaccion/Set`

export const REQUEST_REPORT = `${API_URL}/InformeTipo/Solicitud`

export const GET_APPOINTMENTS = `${API_URL}/Cita/Get`
export const GET_PAST_APPOINTMENTS = `${API_URL}/CitasPasadas/Get`

export const GET_CONFERENCES = `${API_URL}/Videollamada/Get`
export const REQUEST_CONFERENCE = `${API_URL}/Videollamada/Set`

export const GET_OPERATIONS = `${API_URL}/Paciente/GetNotificacionOperaciones`
export const GET_PLACEHOLDERS = `${API_URL}/Cita/GetHuecosLibres`
export const GET_INCOMING_APPOINTMENTS = `${API_URL}/Cita/GetNotificacionCitas`
export const SET_APPOINTMENT = `${API_URL}/Cita/SetModificar`

export const GET_NOTIFICATIONS = `${API_URL}/Notificaciones/Get`
export const SET_NOTIFICATIONS = `${API_URL}/Notificaciones/Set`

export const EMBEDDED_VIDEO_URL = `${API_URL}/Videollamada/StartVideoOJO?idroom=`

export const UPLOAD_FILE = `${API_URL}/File/Upload`
export const GET_UPLOADED_FILES = `${API_URL}/File/List`
export const GET_FILE_PDF = `${API_URL}/File/Download`


/**
 * Apis para la sección de recetas
 */
 export const RECETAS_GET = `${API_URL}/Receta/Get`
 export const RECETAS_GET_PDF = `${API_URL}/Receta/GetPdf`
 
 export const PROTOCOLS_GET = `${API_URL}/Paciente/GetProtocolos`

